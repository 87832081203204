import React, { useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import Matter from "matter-js";

// Emojis to use
const emojis = ["❤️", "😊", "🎉", "😍", "🌟", "🍀"];

const createEmojiCanvas = (emoji) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = 100;
  canvas.height = 100;

  // Set font and alignment
  ctx.font = "80px serif";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";

  // Draw the emoji
  ctx.fillText(emoji, canvas.width / 2, canvas.height / 2);

  // Return the canvas as a texture (data URL)
  return canvas.toDataURL();
};

const MainContent = () => {
  const engineRef = useRef(null);
  const renderRef = useRef(null);
  const runnerRef = useRef(null);
  let emojiTextures = [];
  let emojiInterval;

  const setupPhysics = () => {
    const engine = Matter.Engine.create();
    const runner = Matter.Runner.create(); // Create a separate runner for the engine

    engine.world.gravity.y = 1; // Apply gravity

    const render = Matter.Render.create({
      element: document.body,
      engine: engine,
      options: {
        width: window.innerWidth,
        height: window.innerHeight,
        wireframes: false,
        background: "transparent",
      },
    });

    render.canvas.style.position = "absolute";
    render.canvas.style.top = "0";
    render.canvas.style.left = "0";
    render.canvas.style.zIndex = "0";

    engineRef.current = engine;
    renderRef.current = render;
    runnerRef.current = runner;

    // Create ground and walls
    const ground = Matter.Bodies.rectangle(
      window.innerWidth / 2,
      window.innerHeight - 50,
      window.innerWidth,
      100,
      {
        isStatic: true,
        render: { fillStyle: "transparent" }, // Invisible ground
      }
    );
    const leftWall = Matter.Bodies.rectangle(
      -50,
      window.innerHeight / 2,
      100,
      window.innerHeight,
      { isStatic: true, render: { fillStyle: "transparent" } }
    );
    const rightWall = Matter.Bodies.rectangle(
      window.innerWidth + 50,
      window.innerHeight / 2,
      100,
      window.innerHeight,
      { isStatic: true, render: { fillStyle: "transparent" } }
    );

    Matter.World.add(engine.world, [ground, leftWall, rightWall]);

    Matter.Render.run(render);
    Matter.Runner.run(runner, engine); // Start the runner
  };

  const createEmoji = () => {
    const x = Math.random() * window.innerWidth;
    const emojiTexture =
      emojiTextures[Math.floor(Math.random() * emojiTextures.length)];

    const body = Matter.Bodies.circle(x, 0, 30, {
      restitution: 0.6, // Slight bounce
      friction: 0.2, // Some friction for realistic stacking
      render: {
        sprite: {
          texture: emojiTexture,
          xScale: 0.6,
          yScale: 0.6,
        },
      },
    });

    Matter.World.add(engineRef.current.world, body);
  };

  const handleMouseEnter = () => {
    emojiInterval = setInterval(() => {
      createEmoji();
    }, 500);
  };

  const handleMouseLeave = () => {
    clearInterval(emojiInterval);
  };

  useEffect(() => {
    // Generate emoji textures
    emojiTextures = emojis.map((emoji) => createEmojiCanvas(emoji));

    setupPhysics();

    return () => {
      if (renderRef.current) {
        Matter.Render.stop(renderRef.current);
        Matter.Runner.stop(runnerRef.current); // Stop the runner
        Matter.World.clear(engineRef.current.world);
        Matter.Engine.clear(engineRef.current);
        renderRef.current.canvas.remove();
        renderRef.current.canvas = null;
        renderRef.current.context = null;
        renderRef.current.textures = {};
      }
    };
  }, []);

  return (
    <main className="flex-grow flex items-center justify-center p-4 sm:p-6 md:p-8">
      <div className="text-center px-4 max-w-2xl mx-auto">
        <img
          src="/fav.png"
          alt="Period or Not App Icon"
          className="w-20 h-20 sm:w-24 sm:h-24 rounded-[22%] mx-auto mb-6 sm:mb-8"
        />

        <h1 className="text-2xl sm:text-3xl font-bold text-white mb-3">
          Period or Not
        </h1>
        <p className="text-lg text-gray-400 mb-8">
          Simple (and fun) way to track period
        </p>

        <div className="relative inline-flex" style={{ zIndex: 1 }}>
          <a
            href="https://testflight.apple.com/join/F78BnSFZ"
            target="_blank"
            rel="noopener noreferrer"
            className="relative inline-flex items-center justify-center bg-white text-black font-medium pl-4 pr-4 py-2.5 rounded-full transition-all duration-300 ease-out"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Download
            <span className="bg-black/10 text-black text-xs font-medium px-2 py-0.5 rounded-full ml-2">
              Beta
            </span>
          </a>
        </div>
      </div>
    </main>
  );
};

// Footer Component
const Footer = () => (
  <footer className="py-4 sm:py-6 border-t border-zinc-900">
    <div className="container mx-auto px-4 text-center">
      <nav className="space-x-4 sm:space-x-6">
        <a
          href="/terms"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm sm:text-base text-gray-400 hover:text-white transition-colors duration-200"
        >
          Terms of Service
        </a>
        <a
          href="/privacy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm sm:text-base text-gray-400 hover:text-white transition-colors duration-200"
        >
          Privacy Policy
        </a>
      </nav>
    </div>
  </footer>
);

// Main App Component
function AppContent() {
  const location = useLocation();
  const isLegalPage = ["/terms", "/privacy"].includes(location.pathname);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      {isLegalPage ? (
        <div className="container mx-auto px-4 py-6 sm:py-8 md:py-12 max-w-4xl">
          <Routes>
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
          </Routes>
        </div>
      ) : (
        <>
          <MainContent />
          <Footer />
        </>
      )}
    </div>
  );
}

// Router Setup
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
